import styles from "./Footer.module.css";
import { Row, Col, Nav } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const links = [
  [
    { text: "Our company", href: "https://one.nz/our-company/" },
    {
      text: "Corporate responsibility",
      href: "https://one.nz/our-company/corporate-responsibility/",
    },
    {
      text: "Our executives",
      href: "https://one.nz/our-company/ceo-executive-team/",
    },
    {
      text: "Media centre",
      href: "https://news.one.nz/",
    },
    { text: "Careers", href: "https://one.nz/careers/" },
    { text: "Legal", href: "https://one.nz/legal/" },
    { text: "One NZ", href: "https://one.nz/" },
    { text: "One Good Kiwi", href: "https://onegoodkiwi.nz/" },
  ],
  [
    { text: "Buying online", href: "https://one.nz/help/online-shop/" },
    {
      text: "Browse mobile phones",
      href: "https://one.nz/online-shop/mobile/product-listing/?_bc_fsnf=1&sort=featured&planType=onAccount",
    },
    {
      text: "Browse accessories",
      href: "https://one.nz/online-shop/accessory/product-listing/?_bc_fsnf=1&sort=featured",
    },
    {
      text: "Buy a SIM card",
      href: "https://one.nz/mobile-plans/buy-sim-card/",
    },
    {
      text: "About delivery",
      href: "https://one.nz/help/online-shop/delivery/",
    },
  ],
  [
    {
      text: "Popular Now",
      href: "https://one.nz/specials-promos/",
    },
    {
      text: "iPhone 15 Pro Max",
      href: "https://one.nz/iphone/iphone-15-pro-max/",
    },
    {
      text: "iPhone 15 Pro",
      href: "https://one.nz/iphone/iphone-15-pro/",
    },
    {
      text: "iPhone 15",
      href: "https://one.nz/iphone/iphone-15/",
    },
    {
      text: "iPhone 15 Plus",
      href: "https://one.nz/iphone/iphone-15-plus/",
    },
    {
      text: "iPhone 14",
      href: "https://one.nz/iphone/iphone-14/",
    },
    {
      text: "Find N3",
      href: "https://one.nz/oppo/find-n3/",
    },
    {
      text: "Samsung Z Fold6",
      href: "https://one.nz/samsung-galaxy/galaxy-z-fold6/",
    },
    {
      text: "Samsung S24+ Ultra",
      href: "https://one.nz/samsung-galaxy/galaxy-s24-ultra/",
    },
    {
      text: "Samsung S24+",
      href: "https://one.nz/samsung-galaxy/galaxy-s24-plus/",
    },
  ],
  [
    { text: "Contact us", href: "https://one.nz/contact/" },
    {
      text: "Call us",
      href: "https://one.nz/contact/#call",
    },
    {
      text: "Chat now",
      href: "https://one.nz/contact/?initializechat=bot&initquestion=live&ref=chat",
    },
    {
      text: "Give us feedback",
      href: "https://one.nz/contact/feedback/",
    },
    {
      text: "Find a store",
      href: "https://one.nz/help/store-locations/",
    },
  ],
  [{ text: "Switch to Personal", href: "https://one.nz" }],
  [
    {
      text: "Switch to Business",
      href: "https://one.nz/business/",
    },
  ],
];

export const Footer = () => {
  return (
    <Row className={styles.component}>
      {/* for screen >= 992px */}
      <Row className={[styles.footerRow, styles.hiddenOnSmScreen]}>
        {links.map((linkGroup, index) => (
          <Col md={4} key={index} className={styles.linkGroup}>
            <Nav className="flex-column">
              {linkGroup.map((linkItem, index) => (
                <Nav.Link
                  key={index}
                  href={linkItem.href}
                  className={index === 0 ? styles.superLink : styles.subLink}
                >
                  <span>{linkItem.text}</span>
                  {index === 0 && (
                    <span className={styles.superLinkChevron}>&rsaquo;</span>
                  )}
                </Nav.Link>
              ))}
            </Nav>
          </Col>
        ))}
      </Row>
      {/* for screen < 992px */}
      <Row className={[styles.footerRow, styles.smLinksBlock]}>
        <Col>
          <Accordion flush>
            {links.map((linkGroup, index) => (
              <Accordion.Item
                eventKey={index}
                key={index}
                className={styles.smLinkGroup}
              >
                <Accordion.Header className={styles.smLinkGroupHeader}>
                  <Nav.Link href={linkGroup[0].href}>
                    {linkGroup[0].text}
                  </Nav.Link>
                </Accordion.Header>
                {linkGroup.length > 1 && (
                  <Accordion.Body className={styles.smLinkGroupBody}>
                    {linkGroup.slice(1).map((linkItem, index) => (
                      <Nav.Link key={index} href={linkItem.href}>
                        {linkItem.text}
                      </Nav.Link>
                    ))}
                  </Accordion.Body>
                )}
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
      {/* always show copyright and T&Cs*/}
      <Row className={[styles.footerRow, "align-items-center"]}>
        <Col className="col-lg-7">
          <Nav>
            <Nav.Link href="https://one.nz/legal/terms-conditions/">
              Terms and conditions
            </Nav.Link>{" "}
            |
            <Nav.Link href="https://one.nz/legal/policy/privacy/">
              Privacy Policy
            </Nav.Link>{" "}
            |<Nav.Link href="https://one.nz/sitemap/">Sitemap</Nav.Link>
          </Nav>
        </Col>
        <Col className="col-12 col-lg-5 align-items-center">
          <Nav className={[styles.copyright, "justify-content-end"]}>
            <p>Copyright &copy; 2023 One New Zealand Group Limited</p>
          </Nav>
        </Col>
      </Row>
    </Row>
  );
};
