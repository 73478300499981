import Accordion from "react-bootstrap/Accordion";
import styles from "./FAQ.module.css";
import classNames from "classnames";

const content = [
  {
    question: "If I drop a pin, when will the upgrade be done? ",
    answer:
      "We prioritise coverage updates based on several criteria, but the more pins dropped in an area, the more we know of its priority. Share One Pin with friends, family, and neighbours so they can drop a pin and show us where kiwis are needing coverage the most.",
  },
  {
    question: "Can I see what upgrades are planned in my area?",
    answer: (
      <p>
        You sure can! After dropping a pin, tick the &ldquo;View upgrades&rdquo;
        box to see both planned and completed site upgrades.
      </p>
    ),
  },
  {
    question: "How many pins can I drop?",
    answer: (
      <p>
        You can drop up to five pins in five different locations. After that,
        you can contact our team at{" "}
        <a href="mailto:onepin@one.nz">onepin@one.nz</a> for further coverage
        requests.
      </p>
    ),
  },
  {
    question: "When will I get 5G to my address?",
    answer: (
      <p>
        One NZ 5G is live in parts of cities and towns across Aotearoa. Your pin
        will help inform and prioritise the roadmap for our upcoming 5G
        infrastructure plans. For more information on 5G visit{" "}
        <a href="https://one.nz/5g/">here</a>.
      </p>
    ),
  },
  {
    question: "How much coverage do you have of New Zealand?",
    answer:
      "Our 4G and 5G network connects more than 99% of the population and delivers a great experience with data speed and connectivity, and voice quality with VoLTE.",
  },
  {
    question: "I live rurally. What solutions can you offer me?",
    answer: (
      <p>
        We offer a range of world-leading coverage solutions to help rural New
        Zealand. Get in touch with us{" "}
        <a href="https://one.nz/coveragesolutions/">here</a> and one of our
        rural coverage specialists will be in touch.
      </p>
    ),
  },
];
export const FAQ = () => {
  const QnAs = () => {
    return content.map((x, index) => {
      return (
        <div key={index}>
          <Accordion.Item eventKey={index}>
            <Accordion.Header className={classNames(styles.header)}>
              {x.question}
            </Accordion.Header>
            <Accordion.Body className={classNames(styles.body)}>
              {x.answer}
            </Accordion.Body>
          </Accordion.Item>
        </div>
      );
    });
  };

  return (
    <section id="faqs" className={`${styles.container}`}>
      <div className={styles.faqsWrapper}>
        <h3 className={classNames("one-display-small")}>
          Commonly asked questions
        </h3>
        <Accordion flush>
          <QnAs />
        </Accordion>
      </div>
    </section>
  );
};
